import { AddCircle, AddCircleOutline, Delete, Edit } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Chip, Grid, ListItemIcon, MenuItem, Paper, Stack, TableContainer, Typography } from '@mui/material'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { getDataWithResponse } from 'global/getFunc'
import { MaterialReactTable } from 'material-react-table'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import TambahKorcam from './TambahKorcam'
import TambahRelawan from './TambahRelawan'
import TambahVg from './TambahVg'
import { postDelete } from 'global/postFunc'
import Swal from 'sweetalert2'
import EditKorcam from './EditKorcam'
import EditRelawan from './EditRelawan'

const Tim = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='tambah-korcam' element={<TambahKorcam />} />
            <Route exact path='edit-korcam' element={<EditKorcam />} />
            <Route exact path='tambah-relawan' element={<TambahRelawan />} />
            <Route exact path='edit-relawan' element={<EditRelawan />} />
            <Route exact path='tambah-vote-getter' element={<TambahVg />} />
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    // const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        getDataWithResponse(
            '/entry/tim',
            decryptedUser,
            null,
            setTableData,
            logOut
        )
    }

    const columns = [
        {
            accessorKey: 'nik',
            header: 'NIK',
        },
        {
            accessorKey: 'nama',
            header: 'Nama',
            Cell: ({ cell }) => {
                switch (cell.row.original.status) {
                    case 'Korcam':
                        return <Chip label={cell.row.original.nama} color='primary' />

                    case 'Relawan LA':
                        return <Chip label={cell.row.original.nama} color='success' />

                    default:
                        return <Chip label={cell.row.original.nama} color='warning' />
                }
            }
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL'
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP',
        },
        {
            accessorKey: 'status',
            header: 'Status',
        }
    ]

    const columnVisibility = {
        id_kec: false,
        id_kel: false,
    }

    const deleteVoteGetter = (params) => {
        Swal.fire({
            title: 'Hapus Vote Getter?',
            icon: 'warning',
            text: "Data Pemilih yang direkrut akan Otomatis Terhapus",
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            console.log('Del VG');
            if (result.isConfirmed) {
                postDelete(
                    '/entry/vote-getter/delete',
                    {
                        _method: 'DELETE',
                        nik: params
                    },
                    decryptedUser,
                    null,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    const deleteRelawan = (params) => {
        Swal.fire({
            title: 'Hapus Relawan?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            console.log('Del Rel');
            if (result.isConfirmed) {
                postDelete(
                    '/entry/korkel/delete',
                    {
                        _method: 'DELETE',
                        nik: params
                    },
                    decryptedUser,
                    null,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    const deleteKorcam = (params) => {
        Swal.fire({
            title: 'Hapus Korcam?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            console.log('Del Kor');
            if (result.isConfirmed) {
                postDelete(
                    '/entry/korcam/delete',
                    {
                        _method: 'DELETE',
                        nik: params
                    },
                    decryptedUser,
                    null,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox my={3}>
                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <SoftTypography color='text'>
                                        Tabel Tim
                                    </SoftTypography>
                                }
                                action={
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/entry/tim/tambah-korcam"
                                        component={Link}
                                    // disabled={tableData.length >= 10}
                                    >
                                        Tambah Korcam
                                    </Button>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <SoftBox mb={1} ml={0.5}>
                                            <Typography component="label" variant="body2" fontWeight="bold">
                                                Ket
                                            </Typography>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item>
                                        <Stack direction="row" spacing={1}>
                                            <Chip label="Korcam" color="primary" />
                                            <Chip label="Relawan LA" color="success" />
                                            <Chip label="Vote Getter" color="warning" />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <MaterialReactTable
                                                data={tableData}
                                                columns={columns}
                                                paginateExpandedRows={true}
                                                enableColumnResizing
                                                initialState={{
                                                    showColumnFilters: true,
                                                    columnVisibility: columnVisibility,
                                                    // expanded: true,
                                                    pagination: { pageSize: 20, pageIndex:0 }
                                                }}
                                                enableRowActions={true}
                                                // onColumnFiltersChange={setColumnFilters} 
                                                positionActionsColumn='first'
                                                displayColumnDefOptions={{
                                                    'mrt-row-actions': {
                                                        header: '', //change header text
                                                        size: 50, //make actions column wider
                                                        alignment: 'left'
                                                    },
                                                }}
                                                enableExpanding={true}
                                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                                    // row.original.status &&
                                                    <MenuItem
                                                        key={0}
                                                        component={Link} to={
                                                            row.original.status === 'Korcam' ? '/entry/tim/tambah-relawan' : row.original.status === 'Relawan LA' && '/entry/tim/tambah-vote-getter'
                                                        } 
                                                        state={row.original}
                                                        sx={{ m: 0 }}
                                                        disabled={!row.original.status}
                                                    >
                                                        <ListItemIcon>
                                                            <AddCircle color={row.original.status === '' ? 'disabled' : 'primary'} />
                                                        </ListItemIcon>
                                                        {row.original.status === 'Korcam' ? 'Tambah Relawan' : row.original.status === 'Relawan LA' && 'Tambah Vote Getter'}
                                                    </MenuItem>,
                                                    <MenuItem
                                                        key={1}
                                                        component={Link} to={
                                                            row.original.status === 'Korcam' ? '/entry/tim/edit-korcam' : row.original.status === 'Relawan LA' && '/entry/tim/edit-relawan'
                                                        } 
                                                        state={row.original}
                                                        sx={{ m: 0 }}
                                                        disabled={!row.original.status}
                                                    >
                                                        <ListItemIcon>
                                                            <Edit color={row.original.status === '' ? 'disabled' : 'success'} />
                                                        </ListItemIcon>
                                                        {row.original.status === 'Korcam' ? 'Edit Korcam' : row.original.status === 'Relawan LA' && 'Edit Relawan'}
                                                    </MenuItem>,
                                                    <MenuItem
                                                        key={2}
                                                        onClick={() => {
                                                            // View profile logic...
                                                            closeMenu();
                                                            if (row.original.status === 'Relawan LA') {
                                                                deleteRelawan(row.original.nik);
                                                            }
                                                            else if (row.original.status === 'Korcam') {
                                                                deleteKorcam(row.original.nik)
                                                            } else {
                                                                deleteVoteGetter(row.original.nik)
                                                            }

                                                        }}
                                                        sx={{ m: 0 }}
                                                    >
                                                        <ListItemIcon>
                                                            <Delete color={'error'} />
                                                        </ListItemIcon>
                                                        Hapus
                                                    </MenuItem>
                                                ]}
                                                muiTablePaginationProps={{
                                                    rowsPerPageOptions: [20, 50, 100, { label: 'All', value: tableData.length }],
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                }}
                                            />
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    );
}

export default Tim