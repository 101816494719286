import { Card, CardMedia, Grid, Typography } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox'
import React from 'react'

import logo_la from "assets/images/logo-la-red.png"
import nama from "assets/images/LA-NAMA.png"
import la from 'assets/images/LA2.png'
import bg from "assets/images/bg_la.png"
import jargon from "assets/images/jargon.png"
import { CustomDescrypt } from 'global/func'
import { decodeToken } from 'react-jwt'

const Headerx = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const decodedUser = decryptedUser === '' ? '' : decodeToken(decryptedUser);

    return (
        <SoftBox position="relative" sx={{ paddingTop: '0px !important' }}>
            <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="22rem"
                paddingTop='0px !important'
                // marginTop='-10rem'
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-end', xs: 'center' }} mt={{ lg: 0, xs: -7 }} >
                    <Grid item ml={{ lg: 5 }}>
                        {/* <SoftBox component={Image} src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        {/* <img src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        <CardMedia
                            src={la}
                            component="img"
                            title={decodedUser.status}
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '280px', xs: '280px' },
                                marginRight: 3,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                            
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' mt={-45} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={nama} alt='logo' width='100%' height='110px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={{ lg: 'center', xs: 'center' }} mt={-30} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={jargon} alt='logo' width='100%' height='40px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <SoftBox>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs='auto' >
                            <SoftAvatar
                                src={logo_la}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" my={1} lineHeight={1} width="100%">
                                <Typography variant="h5" width="100%">
                                    {/* {decryptedUser.role === '2' ? 'Admin' : 'Tim'} */}
                                    {decodedUser.nama}
                                </Typography>
                                <Typography variant="body2" width="100%">
                                    {decodedUser.status}
                                </Typography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default Headerx