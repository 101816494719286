import Configurator from 'components/Configurator';
import DashboardLayout from 'components/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Sidenav from 'components/Sidenav';
import React, { useEffect, useState } from 'react'
import { decodeToken, isExpired } from 'react-jwt';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useSoftUIController } from 'context';
import { setMiniSidenav } from 'context';
import { setOpenConfigurator } from 'context';
import SoftBox from 'components/SoftBox';
import { Settings } from '@mui/icons-material';
import userStore from 'store/userStore';

import logo from 'assets/images/logo-la-red.png'
import { CustomDescrypt } from 'global/func';
import adminRoutes from './adminRoutes';
import PendataanDTD from './PendataanDTD';
import VoteGetter from './VoteGetter';
import Korkel from './Korkel';
import ThiTim from './ThiTim';
import PertemuanTerbatas from './Pt';
import Bansos from './Bansos';
import Pemilih from './Pemilih';
import Apk from './Apk';
import Korcam from './Korcam';
import JenisApk from './JenisApk';
import JenisBantuan from './JenisBantuan';
import Footer from 'components/Footer';
import Admin from './Admin';
import SuaraTambahan from './SuaraTambahan';
import Tim from './Tim';
import Headerx from 'components/Header/Headerx';
import Entry from './Entry';

const TemplateAdmin = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const pathname = useLocation();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, direction, openConfigurator, sidenavColor } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    useEffect(() => {
        if (encryptUser === null || encryptUser === undefined) {
            navigate("/", { replace: true });
        }
        else if (isExpired(decryptedUser)) {
            console.log(isExpired(decryptedUser));
            logOut();
            navigate("/", { replace: true });
        }
        else {
            if (decryptedUser.user === null) {
                navigate("/", { replace: true });
            }
            else {
                const decodedUser = decodeToken(decryptedUser);
                // console.log(decodedUser);
                if (decodedUser.role !== 'admin') {
                    navigate("/", { replace: true });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encryptUser, pathname, decryptedUser]);

    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Settings fontSize="default" color="inherit" />
        </SoftBox>
    );

    return (
        <>
            <Sidenav
                color={sidenavColor}
                brand={logo}
                brandName=""
                routes={adminRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                homePage='/admin' />

            <Configurator />
            {configsButton}

            <DashboardLayout>
                <DashboardNavbar homePage='/admin' isMini={miniSidenav} />
                <Headerx title='Admin' />
                <Routes>
                    <Route exact path="" element={<Dashboard />} />
                    <Route exact path="pemilih/*" element={<Pemilih />} />
                    <Route exact path="tim/*" element={<Tim />} />
                    <Route exact path="dtd/*" element={<PendataanDTD />} />
                    <Route exact path="pt/*" element={<PertemuanTerbatas />} />
                    <Route exact path="bansos/*" element={<Bansos />} />
                    <Route exact path="suara-tambahan/*" element={<SuaraTambahan />} />
                    <Route exact path="apk/*" element={<Apk />} />
                    <Route exact path="vg/*" element={<VoteGetter />} />
                    <Route exact path="korkel/*" element={<Korkel />} />
                    <Route exact path="korcam/*" element={<Korcam />} />
                    <Route exact path="thi-tim/*" element={<ThiTim />} />
                    <Route exact path="jenis-apk/*" element={<JenisApk />} />
                    <Route exact path="jenis-bantuan/*" element={<JenisBantuan />} />
                    <Route exact path="admin/*" element={<Admin />} />
                    <Route exact path="entry/*" element={<Entry />} />
                </Routes>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default TemplateAdmin