import { Card, CardMedia, FormControl, Grid, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import nama from "assets/images/LA-NAMA.png"
import la from 'assets/images/LA2.png'
import bg from "assets/images/bg_la.png"
import jargon from "assets/images/jargon.png"
import { decodeToken } from 'react-jwt';
import userStore from 'store/userStore';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import { CustomEncrypt } from 'global/func';
import { LoadingButton } from '@mui/lab';

const Home = () => {
    const pathname = useLocation();
    const navigate = useNavigate();
    const { user, setUser } = userStore((state) => ({ user: state.user, setUser: state.setUser }));
    const userData = localStorage.getItem('AuthPersist');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        role: ''
        
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // console.log(user);
        if (userData !== null) {
            const xx = JSON.parse(userData);

            if (xx.state.user !== null) {
                const decryptedUser = JSON.parse(CustomDescrypt(xx.state.user));

                const decodedUser = decodeToken(decryptedUser)
                // console.log(decodedUser);

                if (decodedUser.role === 'admin') {
                    navigate("/admin", { replace: true });
                }
                else if (decodedUser.role === 'entry') {
                    navigate("/entry", { replace: true });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, pathname])

    // useEffect(() => {
    //     console.log(formData);
    // }, [formData])

    const auth = () => {
        if (formData.username === "" || formData.password === "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Masukkan username dan password"
            })
            return
        }
        setLoading(true)
        axios.post(serverUrl + '/auth', formData)
            .then(function (response) {
                // console.log(response);
                if (response.status === 200) {
                    let data = response.data;
                    let encrypted = CustomEncrypt(JSON.stringify(data));
                    setUser(encrypted);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data,
                    })
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data,
                })
                setLoading(false)
            });
    }

    return (
        <>
            <SoftBox position="relative" p={2}>
                <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="22rem"
                paddingTop='0px !important'
                // marginTop='-10rem'
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-end', xs: 'center' }} mt={{ lg: 0, xs: -5 }} >
                    <Grid item ml={{ lg: 5 }}>
                        <CardMedia
                            src={la}
                            component="img"
                            title='fgkejfhgeujgu'
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '380px', xs: '280px' },
                                margin: 0,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' mt={-58} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={nama} alt='logo' width='100%' height='110px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
                <Grid container justifyContent={{ lg: 'center', xs: 'center' }} mt={-35} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={jargon} alt='logo' width='100%' height='40px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
            </SoftBox>
                <SoftBox sx={{ mt: -10 }}>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                        <Grid item xl={4} lg={6} md={9} >
                            <Card
                                sx={{
                                    backdropFilter: `saturate(200%) blur(30px)`,
                                    // backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                                    // position: "relative",
                                    // mt: -22,
                                    mx: { md: 5, xs: 2 },
                                    py: 2,
                                }}
                            >
                                <SoftBox mt={2}>
                                    <SoftBox px={3}>
                                        <Grid container direction='column' alignItems='center'>
                                            <Grid item>
                                                <SoftBox mb={1}>
                                                    <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient textAlign="center">
                                                        Welcome
                                                    </SoftTypography>
                                                </SoftBox>
                                            </Grid>
                                            <Grid item>
                                                <SoftTypography variant="body2" fontWeight="regular" color="text">
                                                    Enter username and password to sign in
                                                </SoftTypography>
                                            </Grid>
                                        </Grid>
                                    </SoftBox>
                                    <SoftBox p={3}>
                                    <SoftBox component="form" role="form">
                                            <SoftBox mb={2} justifyContent="center">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Username
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="text"
                                                    defaultValue={formData.username}
                                                    onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>

                                            <SoftBox mb={2}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Password
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput
                                                    type="password"
                                                    defaultValue={formData.password}
                                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                    autoComplete='off'
                                                />
                                            </SoftBox>
                                            <SoftBox mb={2}>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Status
                                                    </SoftTypography>
                                                </SoftBox>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={formData.role}
                                                        label="Role"
                                                        onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                                                    >
                                                        {/* <MenuItem value='vote_getter'>Vote Getter</MenuItem>
                                                        <MenuItem value='korkel'>Rel. Brigade</MenuItem>
                                                        <MenuItem value='korcam'>Korcam Brigade</MenuItem>
                                                        <MenuItem value='thi_tim'>Admin SPV</MenuItem> */}
                                                        <MenuItem value='admin'>Admin</MenuItem>
                                                        <MenuItem value='entry'>Entry Data</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </SoftBox>
                                            <SoftBox mt={4} mb={1}>
                                                <LoadingButton variant="contained" color="primary" fullWidth onClick={auth} loading={loading}>
                                                    sign in
                                                </LoadingButton>
                                            </SoftBox>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            </Card>
                        </Grid>
                    </Grid>
                </SoftBox>
            </SoftBox>
        </>

    )
}

export default Home